import axios from 'axios';
import config from '../../../config';
import { store } from '../../../store';
import {
    USER_NOT_FOUND,
    SET_MULTIPLE_COMPANIES,
    FIRST_TIME_USER_LOGIN,
    LOGIN_SUCCESS,
    LOGIN_ERROR
} from '../../../store/login/loginactions';
import { initializeSocket } from '../../../Socket';
import firebaseService from '../../../store/login/firebaseService';

let signInWithGoogle = function () {
    return new Promise(async (resolve, reject) => {
        // console.log('#### clientUserEmail-', clientUserEmail);
        // console.log('#### password-', password);
        if (!firebaseService.auth) {
            console.warn("Firebase Service didn't initialize, check your configuration");
            reject({ message: "Firebase Service didn't initialize, check your configuration" });
        }

        // START Cleanup login Error Message (Invalid email or password)
        store.dispatch({
            type: LOGIN_ERROR,
            payload: {
                email: null,
                password: null
            }
        });
        // END Cleanup login Error Message (Invalid email or password)

        firebaseService
            .signInWithGoogle()
            .then((userCredential) => {
                // Handle successful sign-in
                // console.log('#####-userCredential:', userCredential);
                // const fireBaseUser = userCredential.user;
                // console.log('User signed in:', fireBaseUser);
                if (userCredential.emailVerified) {
                    doClientUserLoginWithFirebaseIdToken()
                        .then((clientUserAuthData) => {
                            resolve(clientUserAuthData);
                        })
                        .catch((err) => {
                            reject(err);
                        });
                } else {
                    store.dispatch({
                        type: LOGIN_ERROR,
                        payload: {
                            email: 'Email verification pending'
                        }
                    });
                    reject({ message: 'Email verification pending' });
                }
            })
            .catch((error) => {
                console.error('Sign-in error:', error);
                const usernameErrorCodes = [
                    'auth/email-already-in-use',
                    'auth/invalid-email',
                    'auth/operation-not-allowed',
                    'auth/user-not-found',
                    'auth/user-disabled'
                ];
                const passwordErrorCodes = ['auth/weak-password', 'auth/wrong-password'];
                const response = {
                    email: usernameErrorCodes.includes(error.code) ? error.message : error.message,
                    password: passwordErrorCodes.includes(error.code) ? error.message : error.message
                };

                store.dispatch({
                    type: LOGIN_ERROR,
                    payload: response
                });
                if (error.code === 'auth/invalid-api-key') {
                    reject({ message: error.message });
                } else {
                    reject({ message: 'Invalid Email or Password' });
                }
            });
    });
};

let doClientUserLoginWithEmailAndPassword = function (clientUserEmail, password) {
    return new Promise(async (resolve, reject) => {
        // console.log('#### clientUserEmail-', clientUserEmail);
        // console.log('#### password-', password);
        if (!firebaseService.auth) {
            console.warn("Firebase Service didn't initialize, check your configuration");
            reject({ message: "Firebase Service didn't initialize, check your configuration" });
        }

        // START Cleanup login Error Message (Invalid email or password)
        store.dispatch({
            type: LOGIN_ERROR,
            payload: {
                email: null,
                password: null
            }
        });
        // END Cleanup login Error Message (Invalid email or password)

        firebaseService
            .firebaseSignInWithEmailAndPassword(clientUserEmail, password)
            .then((userCredential) => {
                // Handle successful sign-in
                const fireBaseUser = userCredential.user;
                // console.log('User signed in:', fireBaseUser);
                if (fireBaseUser.emailVerified) {
                    doClientUserLoginWithFirebaseIdToken()
                        .then((clientUserAuthData) => {
                            resolve(clientUserAuthData);
                        })
                        .catch((err) => {
                            reject(err);
                        });
                } else {
                    store.dispatch({
                        type: LOGIN_ERROR,
                        payload: {
                            email: 'Email verification pending'
                        }
                    });
                    reject({ message: 'Email verification pending' });
                }
            })
            .catch((error) => {
                console.error('Sign-in error:', error);
                const usernameErrorCodes = [
                    'auth/email-already-in-use',
                    'auth/invalid-email',
                    'auth/operation-not-allowed',
                    'auth/user-not-found',
                    'auth/user-disabled'
                ];
                const passwordErrorCodes = ['auth/weak-password', 'auth/wrong-password'];
                const response = {
                    email: usernameErrorCodes.includes(error.code) ? error.message : error.message,
                    password: passwordErrorCodes.includes(error.code) ? error.message : error.message
                };

                store.dispatch({
                    type: LOGIN_ERROR,
                    payload: response
                });
                if (error.code === 'auth/invalid-api-key') {
                    reject({ message: error.message });
                } else {
                    reject({ message: 'Invalid Email or Password' });
                }
            });
    });
};

let doClientUserLoginWithFirebaseIdToken = function () {
    return new Promise(async (resolve, reject) => {
        if (!firebaseService.auth) {
            console.warn("Firebase Service didn't initialize, check your configuration");
            reject({ message: "Firebase Service didn't initialize, check your configuration" });
        } else {
            // Get the current user's ID token
            const currentUser = firebaseService.auth.currentUser;
            if (currentUser) {
                currentUser
                    .getIdToken()
                    .then(async (firebaseUserIdToken) => {
                        // console.log('#### currentUser-', currentUser);
                        // console.log('#### firebaseCurrentUserToken-', firebaseUserIdToken);
                        // console.log('#### localStorage.getItem-companyid-', localStorage.getItem('companyid'));
                        axios
                            .post(config.api.urlHost + config.api.basePath + '/clientLogin', {
                                firebaseUserIdToken: firebaseUserIdToken,
                                clientId: await (localStorage.getItem('companyid') ? localStorage.getItem('companyid') : 0)
                            })
                            .then((respClientLogin) => {
                                let clientUserAuthData = respClientLogin.data;
                                // console.log('#####-clientUserAuthData-', clientUserAuthData);
                                // console.log('#####-clientUserAuthData.responeCode-', clientUserAuthData.responeCode);

                                if (clientUserAuthData.responeCode === 'MULTIPLE_CLIENTS') {
                                    // console.log('#####-clientUserAuthData.clients-', clientUserAuthData.data.clients);
                                    store.dispatch({ type: SET_MULTIPLE_COMPANIES, companies: clientUserAuthData.data.clients });
                                    resolve(clientUserAuthData);
                                } else if (clientUserAuthData.responeCode === 'FIRST_LOGIN') {
                                    store.dispatch({ type: FIRST_TIME_USER_LOGIN, userDetail: clientUserAuthData.data.userDetail });
                                    resolve(clientUserAuthData);
                                } else if (clientUserAuthData.responeCode === 'USERNOTFOUND') {
                                    store.dispatch({ type: USER_NOT_FOUND, data: clientUserAuthData.data });
                                    resolve(clientUserAuthData);
                                } else {
                                    // console.log('## clientUserAuthData.data-', clientUserAuthData.data);
                                    store.dispatch({ type: LOGIN_SUCCESS, userDetail: clientUserAuthData.data.userDetail });

                                    //console.log('## user detail-', clientUserAuthData.data.userDetail);
                                    // localStorage.setItem('loginUserDetail', clientUserAuthData.data.userDetail);
                                    let serverUrl = '';
                                    let socketUrl = '';
                                    let mediaUrl = '';
                                    let host = '';
                                    if (
                                        clientUserAuthData.data.userDetail &&
                                        clientUserAuthData.data.userDetail.serverUrl &&
                                        clientUserAuthData.data.userDetail.serverUrl != ''
                                    ) {
                                        let basePath = '/api';
                                        serverUrl = clientUserAuthData.data.userDetail.serverUrl.trim() + basePath;
                                        socketUrl = clientUserAuthData.data.userDetail.serverUrl.trim();
                                        host = socketUrl;
                                        mediaUrl = clientUserAuthData.data.userDetail.serverUrl.trim() + '/screenMedia';
                                    } else {
                                        socketUrl = config.api.urlHost;
                                        host = socketUrl;
                                        mediaUrl = config.api.urlHost + '/screenMedia';
                                    }
                                    // console.log('## parentClientId-', clientUserAuthData.data.userDetail.parentClientId);

                                    if (null == clientUserAuthData.data.userDetail.parentClientId) {
                                        localStorage.setItem('isParentCompany', true);
                                    } else {
                                        localStorage.setItem('isParentCompany', false);
                                    }

                                    // console.log('## serverUrl-', serverUrl);
                                    localStorage.setItem('companyid', clientUserAuthData.data.userDetail.clientId);
                                    localStorage.setItem('serverUrl', serverUrl);
                                    localStorage.setItem('mediaUrl', mediaUrl);
                                    localStorage.setItem('token', clientUserAuthData.data.token);
                                    localStorage.setItem('host', host);

                                    let socket = initializeSocket(socketUrl);
                                    socket.auth = { token: clientUserAuthData.data.token };
                                    socket.connect();

                                    resolve(clientUserAuthData);
                                }
                            })
                            .catch((err) => {
                                console.log('### err-', err);
                                reject(err);
                            });
                    })
                    .catch((error) => {
                        reject(error);
                    });
            } else {
                reject({ message: 'currentUser not found from firebase' });
            }
        }
    });
};

let getAllClientsByFirebaseIdToken = function () {
    return new Promise(async (resolve, reject) => {
        if (!firebaseService.auth) {
            console.warn("Firebase Service didn't initialize, check your configuration");
            reject({ message: "Firebase Service didn't initialize, check your configuration" });
        } else {
            // Get the current user's ID token
            const currentUser = firebaseService.auth.currentUser;
            if (currentUser) {
                currentUser
                    .getIdToken()
                    .then(async (firebaseUserIdToken) => {
                        axios
                            .post(config.api.urlHost + config.api.basePath + '/findAllUserClients', {
                                firebaseUserIdToken: firebaseUserIdToken
                            })
                            .then((clientsArray) => {
                                // console.log('#### clientsArray-', clientsArray);
                                resolve(clientsArray);
                            })
                            .catch((err) => {
                                console.log('### err-', err);
                                reject(err);
                            });
                    })
                    .catch((error) => {
                        reject(error);
                    });
            } else {
                reject({ message: 'currentUser not found from firebase' });
            }
        }
    });
};
export default {
    doClientUserLoginWithFirebaseIdToken,
    doClientUserLoginWithEmailAndPassword,
    getAllClientsByFirebaseIdToken,
    signInWithGoogle
};
