import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';

import {
    Box,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    Paper,
    DialogContent,
    Grid,
    Typography,
    Divider,
    CircularProgress
} from '@mui/material';

import { useTheme } from '@mui/material/styles';

// project imports
import AuthLoginService from '../../../../views/authentication/login/LoginService';

import { getSocket } from '../../../../Socket';

const SwitchCompanyModal = (props) => {
    const [isLoading, setLoading] = useState(false);

    const theme = useTheme();
    const [clientList, setClientList] = useState([
        {
            id: 1,
            clientName: ''
        }
    ]);
    let navigate = useNavigate();
    useEffect(() => {
        setLoading(true);

        AuthLoginService.getAllClientsByFirebaseIdToken()
            .then((clientsArray) => {
                // console.log('#### clientsArray-useEffect-', clientsArray);
                let clientsDataArray = clientsArray.data.data;
                // console.log('####-clientsDataArray-', clientsDataArray);

                setClientList(clientsDataArray);
                setLoading(false);
            })
            .catch((err) => {
                setLoading(false);
                console.log('err-', err);
            });
    }, []);

    const handleRowClick = (clientId) => {
        // console.log('###-clientId-', clientId);
        const socket = getSocket(); // Get the initialized socket instance
        if (socket) {
            socket.disconnect();
        }
        localStorage.setItem('companyid', clientId);
        navigate('/dashboard');
        window.location.reload();
        if (props.handleModalClose) {
            props.handleModalClose();
        }
    };

    return (
        <>
            <DialogContent dividers={scroll === 'paper'}>
                {isLoading ? (
                    <Box sx={{ minWidth: 650, display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                        <CircularProgress sx={{ margin: 'auto' }} />
                    </Box>
                ) : clientList && clientList.length > 0 ? (
                    <TableContainer component={Paper}>
                        <Table sx={{ minWidth: 650 }} size="small" aria-label="simple table">
                            <TableHead>
                                <TableRow>
                                    <TableCell align="left">Company Name</TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {clientList.map((row) => (
                                    <TableRow
                                        key={row.id}
                                        sx={{ '&:last-child td, &:last-child th': { border: 0 }, cursor: 'pointer' }}
                                        onClick={(e) => {
                                            handleRowClick(row.clientId);
                                        }}
                                    >
                                        {/* <TableCell align="left">{row.clientName}</TableCell> */}
                                        <TableCell align="left">
                                            {row.hierarchy == 'Customer' ? (
                                                row.clientName
                                            ) : row.hierarchy == 'Oem' ? (
                                                <>
                                                    {row.clientName} - <strong>OEM</strong>
                                                </>
                                            ) : row.hierarchy == 'Tier_1_Supplier' ? (
                                                <>
                                                    {row.clientName} - <strong>Supplier</strong>
                                                </>
                                            ) : (
                                                row.clientName
                                            )}
                                        </TableCell>
                                    </TableRow>
                                ))}
                            </TableBody>
                        </Table>
                    </TableContainer>
                ) : (
                    <Box sx={{ display: 'flex', justifyContent: 'center', height: '70vh' }}>
                        {' '}
                        <Typography variant="h5"> No Data Found</Typography>
                    </Box>
                )}
            </DialogContent>
        </>
    );
};

export default SwitchCompanyModal;
