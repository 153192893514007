// material-ui
import { Typography } from '@mui/material';
import { useSelector } from 'react-redux';

// project imports
import NavGroup from './NavGroup';
// import menuItem from 'menu-items';
import getMenuItems from 'menu-items';

// ==============================|| SIDEBAR MENU LIST ||============================== //

const MenuList = () => {
    const userDetail = useSelector((state) => state.login.userDetail);
    const menuItem = getMenuItems(userDetail);

    const navItems = menuItem.items.map((item) => {
        switch (item.type) {
            case 'group':
                return <NavGroup key={item.id} item={item} />;
            default:
                return (
                    <Typography key={item.id} variant="h6" color="error" align="center">
                        Menu Items Error
                    </Typography>
                );
        }
    });

    // console.log('#####-navItems-navItems', navItems);
    return <>{navItems}</>;
};

export default MenuList;
