// assets
import {
    IconTypography,
    IconPalette,
    IconShadow,
    IconWindmill,
    IconChartHistogram,
    Icon24Hours,
    IconAntenna,
    IconHeartRateMonitor,
    IconBellRingingFilled
} from '@tabler/icons-react';

// constant
const icons = {
    IconTypography,
    IconPalette,
    IconShadow,
    IconWindmill,
    IconChartHistogram,
    Icon24Hours,
    IconAntenna,
    IconHeartRateMonitor,
    IconBellRingingFilled
};

// ==============================|| REALTIME MENU ITEMS ||============================== //

const oemDatamonitor = {
    id: 'oemDatamonitor',
    title: 'Data Monitor',
    type: 'group',
    children: [
        {
            id: 'oem-scada-screens',
            title: 'Screens',
            type: 'item',
            url: '/datamonitor/screens/table-view',
            icon: icons.IconHeartRateMonitor,
            breadcrumbs: false
        }
    ]
};

export default oemDatamonitor;
