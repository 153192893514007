import { lazy } from 'react';

// project imports
import Loadable from 'ui-component/Loadable';

// devices routing
const Devices = Loadable(lazy(() => import('./devices/Device')));
const Gateway = Loadable(lazy(() => import('./gateway/Gateway')));

// ==============================|| MAIN ROUTING ||============================== //

const ModbusRoutes = {
    path: 'modbus',
    children: [
        {
            path: 'devices',
            element: <Devices />
        },
        {
            path: 'gateway',
            element: <Gateway />
        }
    ]
};

export default ModbusRoutes;
