import { lazy } from 'react';

// project imports
import Loadable from 'ui-component/Loadable';

const PartsConfig = Loadable(lazy(() => import('./parts-config/PartsConfig')));
const PredictiveConfig = Loadable(lazy(() => import('./predictive-config/PredictiveConfig')));
const PreventiveConfig = Loadable(lazy(() => import('./preventive-config/PreventiveConfig')));

// ==============================|| MAIN ROUTING ||============================== //

const MaintenanceRoutes = {
    path: 'maintenance',
    children: [
        {
            path: 'parts-config',
            element: <PartsConfig />
        },
        {
            path: 'predictive-config',
            element: <PredictiveConfig />
        },
        {
            path: 'preventive-config',
            element: <PreventiveConfig />
        }
    ]
};

export default MaintenanceRoutes;
