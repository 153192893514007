import { lazy } from 'react';

// project imports
import Loadable from 'ui-component/Loadable';

// RealtimeTableConfig routing
const ScadaScreenConfig = Loadable(lazy(() => import('./screenConfiguration/ScadaScreenConfig')));

// ==============================|| MAIN ROUTING ||============================== //

const ScadsScreenRoutes = {
    path: 'scada',
    children: [
        {
            path: 'screenconfig',
            element: <ScadaScreenConfig />
        }
    ]
};

export default ScadsScreenRoutes;
