// import * as reduxModule from 'redux';
// import { applyMiddleware, compose, createStore } from 'redux';
// import reducer from './reducer';
// import { thunk } from 'redux-thunk';

// // ==============================|| REDUX - MAIN STORE ||============================== //

// reduxModule.__DO_NOT_USE__ActionTypes.REPLACE = '@@redux/INIT';

// const composeEnhancers =
//     process.env.NODE_ENV !== 'production' && typeof window === 'object' && window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__
//         ? window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__({
//               // Specify extension’s options like name, actionsBlacklist, actionsCreators, serialize...
//           })
//         : compose;

// const enhancer = composeEnhancers(applyMiddleware(thunk));

// const store = createStore(reducer, enhancer);
// const persister = '';

// export { store, persister };

import { configureStore } from '@reduxjs/toolkit';
import reducer from './reducer';
// import thunk from 'redux-thunk';

const store = configureStore({
    reducer,
    // middleware: (getDefaultMiddleware) => getDefaultMiddleware().concat(thunk),
    devTools: process.env.NODE_ENV !== 'production' // Enables Redux DevTools only in development mode
});

const persister = '';

export { store, persister };
