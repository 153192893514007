import { lazy } from 'react';

// project imports
import Loadable from 'ui-component/Loadable';

// PollingIntervalConfig routing
const PollingIntervalConfig = Loadable(lazy(() => import('./PollingIntervalConfig')));
const ChartConfig = Loadable(lazy(() => import('./chart-config/ChartConfig')));

// ==============================|| MAIN ROUTING ||============================== //

const PollingIntervalRoutes = {
    path: 'pollingInterval',
    children: [
        {
            path: 'config',
            element: <PollingIntervalConfig />
        },
        {
            path: 'chart-config',
            element: <ChartConfig />
        }
    ]
};

export default PollingIntervalRoutes;
