module.exports = {
    // basename: only at build time to set, and Don't add '/' at end off BASENAME for breadcrumbs, also Don't put only '/' use blank('') instead,
    // like '/berry-material-react/react/default'
    basename: '',
    defaultPath: '/dashboard',
    fontFamily: `'Roboto', sans-serif`,
    borderRadius: 12,

    api: {
        urlHost: 'http://localhost:5001',
        basePath: '/api',
        baseMediaPath: '/screenMedia'
    },
    firebaseConfig: {
        // apiKey           : "YOUR_API_KEY",
        // authDomain       : "your-app.firebaseapp.com",
        // databaseURL      : "https://your-app.firebaseio.com",
        // projectId        : "your-app",
        // storageBucket    : "your-app.appspot.com",
        // messagingSenderId: "YOUR_MESSAGING_SENDER_ID"
        apiKey: 'AIzaSyCbfRuQyJz08FADt2C8bQcQUbxgOPlQ-K4',
        authDomain: 'uat-industrial-iot.firebaseapp.com',
        projectId: 'uat-industrial-iot',
        storageBucket: 'uat-industrial-iot.appspot.com',
        messagingSenderId: '226050783139',
        appId: '1:226050783139:web:f4b5fa430eb8c748478c16',
        measurementId: 'G-886BP6494K'
    }
};
